import React from 'react';
import { Route, Redirect } from 'react-router-dom';
const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const token = localStorage.getItem('token');
  return (
    <Route
      path={path}
      {...rest}
      render={props => {
        return token !== null ? <Component {...props} /> : <Redirect to='/' />;
      }}
    />
  );
};

export default ProtectedRoute;
