import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Spinner } from 'reactstrap';

ReactDOM.render(
  <Suspense
    fallback={
      <div className='initial-app-loader d-flex justify-content-center align-items-center'>
        <div className='d-flex flex-column align-items-center'>
          <Spinner color='success' />
          <h5>Please Wait....</h5>
        </div>
      </div>
    }
  >
    <App />
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
