import React from "react";
import Logo from '../public/img/logo.png';
import PageNotFound from "../public/img/404.svg";
const NotFound = () => (
  <div className='bg-dark'>
    <div className="not-found">
      <img
        className='img-fluid mx-auto d-block mb-5 logo'
        src={Logo}
        alt='Vidadata'
      />
      <div className="col-xl-4 text-center">
        <img
          className="img-fluid"
          src={PageNotFound}
          alt="Page not Found"
        />
        <h3 className="brand-color pt-4 d-block text-center">Opps! 404 Error</h3>
        <a href="https://www.vidadata.com" className="brand-color pt-4 d-block text-center">
          Return to Home Page
        </a>
      </div>
    </div>
  </div>
);
export default NotFound;
